import * as React from 'react';
import { IHtmlComponentProps } from '../HtmlComponent.types';
import styles from './styles/HtmlComponent.scss';

const HtmlComponent: React.FC<IHtmlComponentProps> = props => {
  const { id, url, allow, isHidden, scrolling, sandbox } = props;

  // detailed explanation on why is this here: https://jira.wixpress.com/browse/WCD-5445
  const display = isHidden ? 'none' : undefined;

  const defaultAttributes = {
    name: 'htmlComp-iframe',
    width: '100%',
    height: '100%',
    style: { display },
  };

  return (
    <wix-iframe id={id} data-src={url} className={styles.root}>
      <div className={styles.iframeHolder}>
        <iframe
          sandbox={sandbox}
          scrolling={scrolling}
          title="htmlComp-iframe"
          className={styles.iframe}
          allow={allow}
          data-src={url}
          {...defaultAttributes}
        />
      </div>
    </wix-iframe>
  );
};

export default HtmlComponent;
